<script>
import moment from "moment";

export default {
  name: "ListaReports",
  props:{
    reports: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods:{
    formatarDataHora(data) {
      //Formatar data e hora (YYYY-MM-DD HH:MM:SS) com moment.js
      return moment(data).format("DD/MM/YYYY HH:mm:ss");
    }

  }
}
</script>

<template>
  <div>

    <table class="table table-striped table-hover table-sm">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Nome</th>
          <th scope="col">Motivo</th>
          <th scope="col">Data</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="report in reports" :key="report.Id">

          <td><img :src="report.usuario.Photo" alt="img" class="imgthumbnail" /> </td>
          <td>{{ report.usuario.Nome }}</td>
          <td>{{ report.Motivo }}</td>
          <td>{{ this.formatarDataHora(report.created_at) }}</td>
        </tr>
      </tbody>
    </table>

  </div>

</template>

<style scoped>
.imgthumbnail {
  border-radius: 20px;
  width: 23px;
  height: 23px;
}
</style>