<script>
import MediaHistoria from "@/components/MediaHistoria.vue";

export default {
  name: "HistoriaView",
  components: {
    MediaHistoria

  },
  props: {
    historia: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      story: {
        Titulo: "",
        Subtitulo: "",
        Descritivo: "",
        Destaque: null,
        Capa: "",
        Thumbnail: "",
        Views: "",
        Tipo: "",
        Status: true,
        NotaMediaUsuario: "",
        created_at: "",
        reportes: [],
        historia_midia: [],
        usuario: {
          Nome: "",
          Id: "",
          Photo: "",
          tipo_usuario: {
            Id: "",
            Descricao: "",
          },
        },
        categoria: {
          Categoria: "",
        },
        destaque: []
      },
      totalReports: 0,
    }
  },
  computed: {
    computedStyle() {
      if (this.story.Capa) {
        return {
          backgroundImage: `linear-gradient(to bottom, rgba(36, 43, 48, .7), #242B30), url(${this.story.Capa})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top',
          top: 0,
        };
      }
      return {};
    },
  },
  mounted() {
    if (!this.loading) {
      this.story = this.historia;
    }
  },
  methods: {
    shortenDescription(description) {
      if (!description) {
        return "";
      }
      description = description.trim();
      return description.split(" ").splice(0, 20).join(" ") + "...";
    },
    CountReports() {
      this.totalReports = this.story.reportes.length;
    },
    formattedHtmlString(string) {
      // Substituir quebras de linha por <br>
      // Encontrar a primeira ocorrência da tag <a
      const anchorIndex = string.indexOf('<a');

      // Dividir a string em duas partes: antes e depois da primeira tag <a
      const beforeAnchor = string.substring(0, anchorIndex);
      const afterAnchor = string.substring(anchorIndex);

      // Substituir quebras de linha por <br> apenas na parte antes da tag <a
      const formattedBeforeAnchor = beforeAnchor.replace(/(?:\r\n|\r|\n)/g, '<br>');

      // Concatenar a parte formatada com a parte após a tag <a
      return formattedBeforeAnchor + afterAnchor;
    },

  },
}
</script>

<template>
  <div>
    <div class="capaHistoria" :style="computedStyle">
      <span class="HistoriaUsuario">
        <div class="row">
          <div class="col-1">
            <img :src="story.usuario.Photo" class="rounded-circle" width="30" height="30" alt="...">
          </div>
          <div class="col-11 ps-3 HistoriaUsuarioUser">
            {{ story.usuario.Nome}}
          </div>
        </div>
      </span>
      <span class="font-bold font-bigger TituloHistoria ">
      {{ story.Titulo }}
      </span>

      <span class="font-bold font-bigger TituloNota ">
      Nota: {{ story.NotaMediaUsuario }} <ph-star :size="16" weight="fill" style="color: #000000" />
      </span>
    </div>
    <hr class="HistoriaHr">
    <span class="HistoriaSubtitulo">
    <ph-book-open :size="28" style="color:#bdbdbd " />
    <br>
    {{ story.Subtitulo }}
    </span>
    <span class="HistoriaDescritivo">
    {{ story.Descritivo }}
    </span>
    <hr class="HistoriaHr">

    <div class="row my-5" v-for="HistoriaMidia in story.historia_midia" :key="HistoriaMidia.id" >

      <div class="col-sm-12 m-0 p-0">

            <MediaHistoria :midia="HistoriaMidia" />

            <div class="HistoriaSubtitulo">
              {{ HistoriaMidia.legenda }}
            </div>

            <div class="HistoriaDescritivo" v-html="formattedHtmlString(HistoriaMidia.descritivo)"></div>


      </div>

    </div>
  </div>
</template>

<style scoped>
.capaHistoria {
  position: relative;
  display: block;
  width: 100%;
  height: 700px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  margin-bottom: 20px;
}

.TituloHistoria{
  white-space: pre-wrap;
  font-family: 'Helvetica Now Display Extra Bold', sans-serif !important;
  display: block;
  position: absolute;
  bottom: 80px;
  left: 25px;
  font-size: 2rem;
  color: #bdbdbd !important;
  min-height: 70px !important;
}

.TituloNota{
  white-space: pre-wrap;
  font-family: 'Helvetica Now Display Extra Bold', sans-serif !important;
  display: block;
  position: absolute;
  bottom: 10px;
  left: 25px;
  font-size: 1rem;
  color: #ffffff !important;
  min-height: 70px !important;
}

.HistoriaUsuarioUser{
  font-family: 'Helvetica Now Display Extra Bold', sans-serif !important;
}

.HistoriaUsuario{
  font-family: 'Helvetica Now Display Extra Bold', sans-serif !important;
  white-space: pre-wrap;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 160px;
  left: 25px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white !important;

}

.HistoriaSubtitulo {

  font-family: 'Helvetica Now Display Extra Bold', sans-serif !important;
  display: block;
  font-size: 2rem;
  min-height: 70px !important;

}
.HistoriaDescritivo {
  font-family: 'Helvetica Now Display Bold', sans-serif  !important;
  color: #7e7f80;
  font-size: 1rem;
  font-weight: 400;
}

.HistoriaHr {
  margin: 30px 0px;
  border-color: rgb(218, 218, 218);
  opacity: 0.25;
}
</style>