<template>
  <div>
    <component v-if="isImage" :is="ImageView" :src="midia.url" class="ImageView" />
    <component v-else-if="isVideo" :is="VideoPlayer" :url="midia.url" />
    <component v-else-if="isAudio" :is="AudioPlayer" :src="midia.url" />
    <div v-else>
     <p>teste else</p>
    </div>
  </div>
</template>

<script>
import { imageTypes, videoTypes, audioTypes } from '@/helpers/mediaTypes';
import Button from '@/components/Button';
import DefaultVideoPlayer from '@/components/DefaultVideoPlayer';

export default {
  name: 'EducationViewAttachment',
  components: {
    Button,
    DefaultVideoPlayer,
  },
  props: {
    midia: Object,
    attachment: Object,
    last: Boolean,
    disabled: Boolean,
  },
  computed: {
    isImage() {
      return imageTypes.includes(this.midia.extensao.toLowerCase());
    },
    isVideo() {
      return videoTypes.includes(this.midia.extensao.toLowerCase());
    },
    isAudio() {
      return audioTypes.includes(this.midia.extensao.toLowerCase());
    },
    ImageView() {
      return 'img';
    },
    VideoPlayer() {
      return 'DefaultVideoPlayer';
    },
    AudioPlayer() {
      return 'audio';
    },
  },
  methods: {
    fetchClickedFile(src) {
      const link = document.createElement('a');
      link.setAttribute('download', '');
      link.href = src;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style  scoped>
.ImageView {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  cursor: pointer;
}
</style>
