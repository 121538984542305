<script>
export default {
  name: "TabelaViewsHistoria",
  props:{
    views: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  methods:{
    formatarData(mes,ano){
      //colocar data apartor do 01 e colocar o 0 no mes quando nao tem
      if(mes < 10){
        mes = "0" + mes;
      }

      return mes + "/" + ano;
    }
  }
}
</script>

<template>
<table class="table table-striped table-sm">
  <thead>
    <tr>
      <th scope="col">Mês</th>
      <th scope="col">Visualizações</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="view in views" :key="view.id">
      <td>{{ formatarData(view.mes,view.ano) }}</td>
      <td>{{ view.total }}</td>
    </tr>
  </tbody>
</table>
</template>

<style scoped>

</style>