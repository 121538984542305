<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="d-flex">
            <div class="card card-body">

              <div class="row">
                <div class="col-sm-4 border-end">
                  <HistoriaView :historia="Historia" :loading="loading" v-if="!loading"/>
                  <span v-else>
                   Carregando...
                 </span>
                </div>
                <div class="col-sm-8">
                  <h3 class="TituloHistoriaAction">
                    História #{{ HistoriaId }}

                    <span v-if="Historia.Destaque" class="destaqueHistoria">
                            | Destaque
                          </span>
                    <span v-if="Historia.Tipo =='Padrao'" class="exemploHistoria">
                            | Exemplo
                          </span>

                  </h3>
                  <hr>

                  <div v-if="!loading">
                    <div class="row">
                      <div class="col-sm-2">
                        <Button text="Excluir"
                                size="thin"
                                v-if="Historia.Status"
                                :handler="()=>excluirHistoria(0)"
                                color="destructive"/>
                        <Button v-else
                                :handler="()=>excluirHistoria(1)"
                                color="secondary"
                                size="thin"
                                text="Restaurar"/>

                        <Button text="Não destacar"
                                size="thin"
                                class="mt-1"
                                v-if="Historia.Destaque"
                                :handler="()=>destaqueHistoria(0)"
                                color="primary"/>
                        <Button v-else
                                class="mt-1"
                                :handler="()=>destaqueHistoria(1)"
                                color="primary-outline"
                                size="thin"
                                text="Destacar"/>

                        <Button text="Não template"
                                size="thin"
                                class="mt-1"
                                v-if="Historia.Tipo === 'Padrao'"
                                :handler="()=>exemploHistoria(0)"
                                color="warning"/>
                        <Button v-else
                                class="mt-1"
                                :handler="()=>exemploHistoria(1)"
                                color="warning-outline"
                                size="thin"
                                text="Tonar template"/>

                        <Button text="- como funciona?"
                                size="thin"
                                class="mt-1"
                                v-if="Historia.Tipo === 'Padrao'"
                                :handler="()=>comoFuncionaHistoria(0)"
                                color="intelligence"/>
                        <Button v-else
                                class="mt-1"
                                :handler="()=>comoFuncionaHistoria(1)"
                                color="intelligence-outline"
                                size="thin"
                                text="+ como funciona?"/>


                      </div>
                      <div class="col-sm-10">
                        <div class='row'>
                          <div class="col-sm-12">
                            <a :href="Historia.evento_log[0].url_share" target="_blank">
                            {{Historia.evento_log[0].url_share}}
                            </a>
                          </div>
                        </div>
                        <div class='row'>
                          <div class="col-sm-12">
                            <div class="hstack gap-2 ms-2">
                             <span class="badge rounded-pill text-bg-light colorNota ">
              <svg v-if="Historia.Status" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                   class="bi bi-toggle-on text-success" viewBox="0 0 16 16">
                <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/>
              </svg>

            <svg v-else xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-toggle-off text-danger" viewBox="0 0 16 16">
              <path
                  d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/>
            </svg>
            <span>
            {{ Historia.Status ? 'Ativo' : 'Inativo' }}
            </span>

          </span>
                              <span class="badge rounded-pill text-bg-light colorNota ">

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye"
                 viewBox="0 0 16 16">
              <path
                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
            </svg>
            <span>
            {{ (Historia.Views) }}
            </span>

          </span>
                              <span class="badge rounded-pill text-bg-light colorNota ">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill"
               viewBox="0 0 16 16">
            <path
                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
          </svg>
          <span>
            {{ parseFloat(Historia.NotaMediaUsuario).toFixed(1) }}
          </span>
          </span>
                              <span class="badge rounded-pill text-bg-light colorNota ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-exclamation-octagon" viewBox="0 0 16 16">
              <path
                  d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
              <path
                  d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
            </svg>
            <span>
            {{ totalReports }}
            </span>
          </span>
                              <span class="badge rounded-pill text-bg-light colorNota ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shield-shaded" viewBox="0 0 16 16">
                                  <path fill-rule="evenodd" d="M8 14.933a.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067v13.866zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                                </svg>
                                <span>
                                {{ HistoriaPrivacidade }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-sm-12 ">
                        <span class="TituloHistoriaActionItem">
                          -> Reportes/Denúncias
                        </span>
                      </div>
                      <div class="col-sm-12">
                        <ListaReports :reports="Historia.reportes" :loading="loading" />
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-sm-12 ">
                        <span class="TituloHistoriaActionItem">
                          -> Visualizações
                        </span>
                      </div>
                      <div class="col-sm-6">
                        <GraficoViewsHistoria :views="Historia.visualizacao" :loading="loading" />
                      </div>
                      <div class="col-sm-6">
                        <TabelaViewsHistoria :views="Historia.visualizacao" :loading="loading" />
                      </div>
                    </div>

                  </div>

                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.colorNota {
  color: #1a1d21;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  background: unset !important;
}

.colorNota span {
  position: relative;
  display: inline-flex;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  align-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: normal;
  line-height: 01rem;


}

.destaqueHistoria {
  font-family: "Helvetica Now Display Extra Black", sans-serif !important;
  color: #00a0d9;
  align-items: center;

  font-weight: 500;


}
.exemploHistoria {
  font-family: "Helvetica Now Display Extra Black", sans-serif !important;
  color: #228300;
  align-items: center;

  font-weight: 500;

}

.TituloHistoriaAction {
  font-family: "Helvetica Now Display Extra Black", sans-serif !important;
  color: #000000;
  align-items: center;
  font-size: 2rem;
  font-weight: 500;
}

.TituloHistoriaActionItem {
  font-family: "Helvetica Now Display Extra Bold", sans-serif !important;
  color: #000000;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
}
</style>
<script>
import Layout from "../../layouts/main.vue";
import Button from "@/components/Button.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from "axios";
import HistoriaView from "@/components/HistoriaView.vue";
import Swal from "sweetalert2";

export default {
  name: "historia",
  page: {
    title: "História",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ],
  },
  data() {
    return {
      title: "História MedStory",
      items: [
        {
          text: "medstory",
          href: "/",
        },
        {
          text: "história",
          active: true,
        },
      ],
      HistoriaId: 0,
      Historia: {
        reportes: [],
      },
      totalReports: 0,
      HistoriaPrivacidade: 'Público',

      data: [],
      page: 1,
      perPage: 12,
      total: 0,
      pages: [],
      searchQuery: '',
      loading: true,
      currentPage: 1,
      FiltroCategoria: [
        {
          api: '',
          text: "Todos",

        },
        {
          api: 'De',
          text: "Destaques",

        },
        {
          api: 'Ma',
          text: "Mais acessadas",

        },
        {
          api: 'Me',
          text: "Melhores notas",

        },
        {
          api: 'Re',
          text: "Reportadas",

        },
        {
          api: 'Ex',
          text: "Removidas",

        }
      ],
      FilterSelected: '',

    };
  },
  computed: {},
  mounted() {
    //pegar o parametro da url e colocar no console.log
    console.log(this.$route.params.Id);
    this.HistoriaId = this.$route.params.Id;
    this.LoadHistoria();

  },
  watch: {},
  created() {
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.total / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {

      let page = this.page;
      let perPage = data.per_page;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
    async LoadHistorias(page = this.currentPage, perPage = this.perPage, query = this.searchQuery, FilterSelected = this.FilterSelected) {

      //Exibir loading spinner aqui
      this.loading = true;

      const result = await axios.get(process.env.VUE_APP_API + `/v3/historias?page=${page}&per_page=${perPage}&palavra=${query}&Filtro=${FilterSelected}`, {});
      // Atualizar o estado com os novos dados
      this.data = result.data.data;
      this.total = result.data.total;
      // Atualizar a página atual e o número total de páginas
      this.currentPage = page;
      this.totalPages = Math.ceil(this.total / perPage);
      //this.paginate(this.data);
      this.setPages();
      //this.displayedPosts();
      this.loading = false;
    },
    async LoadHistoria() {

      //Exibir loading spinner aqui
      this.loading = true;
      const HistoriaId = this.HistoriaId;
      const result = await axios.get(process.env.VUE_APP_API + `/v3/historia/${HistoriaId}`, {});
      // Atualizar o estado com os novos dados
      this.Historia = result.data;
      this.CountReports();
      this.Privacidade();
      this.loading = false;
    },
    setFilterSelected(c) {
      this.FilterSelected = c.api;
    },
    excluirHistoria(Status) {
      Swal.fire({
        title: 'Tem certeza?',
        text: "Você vai alterar a história selecionada!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {

          axios.post(process.env.VUE_APP_API + `/v3/alterarhistoria`, {
            Status: Status,
            Id: this.HistoriaId,
          }).then((response) => {
            if (response.status === 200) {
              Swal.fire(
                  'Alterado!',
                  'A história foi alterada.',
                  'success'
              )

              return this.LoadHistoria();
            }
          })
        }
      })
    },
    destaqueHistoria(Status) {
      Swal.fire({
        title: 'Tem certeza?',
        text: "Você vai alterar a história selecionada!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {

          axios.post(process.env.VUE_APP_API + `/v3/alterarhistoria`, {
            Destaque: Status,
            Id: this.HistoriaId,
          }).then((response) => {
            if (response.status === 200) {
              Swal.fire(
                  'Alterado!',
                  'A história foi alterada.',
                  'success'
              )

              return this.LoadHistoria();
            }
          })
        }
      })
    },
    exemploHistoria(Status) {
      Swal.fire({
        title: 'Tem certeza?',
        text: "Você vai alterar a história selecionada!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {

          axios.post(process.env.VUE_APP_API + `/v3/alterarhistoria`, {
            Tipo: Status,
            Id: this.HistoriaId,
          }).then((response) => {
            if (response.status === 200) {
              Swal.fire(
                  'Alterado!',
                  'A história foi alterada.',
                  'success'
              )

              return this.LoadHistoria();
            }
          })
        }
      })
    },
    comoFuncionaHistoria(Status) {
      Swal.fire({
        title: 'Tem certeza?',
        text: "Você vai alterar a história selecionada!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {

          axios.post(process.env.VUE_APP_API + `/v3/alterarhistoria`, {

            comoFuncionaHistoria: Status,
            Id: this.HistoriaId,
          }).then((response) => {
            if (response.status === 200) {
              Swal.fire(
                  'Alterado!',
                  'A história foi alterada.',
                  'success'
              )

              return this.LoadHistoria();
            }
          })
        }
      })
    },
    CountReports() {

      this.totalReports = this.Historia.reportes.length;

    },
    Privacidade(){

      if(this.Historia.Publico === '1'){
        this.HistoriaPrivacidade = 'Público';
      }else{
        this.HistoriaPrivacidade = 'Privado';
      }

    }
  },
  components: {
    Button,
    HistoriaView,
    Layout,
    PageHeader,
  },
};
</script>
<script setup>
import ListaReports from "@/components/ListaReports.vue";
import GraficoViewsHistoria from "@/components/GraficoViewsHistoria.vue";
import TabelaViewsHistoria from "@/components/TabelaViewsHistoria.vue";
</script>