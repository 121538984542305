<template>
  <button
      @click="handleClick"
      :class="buttonClass"
      :data-size="size"
      :data-color="isNamedColor"
      :style="buttonStyle"
      :disabled="disabled || loading"
      :type="type"
  >
    <template v-if="loading">
      <!-- Aqui você pode colocar o componente ou animação de carregamento -->
      <span>
        <!-- CircleNotch ou outro componente de carregamento -->
      </span>
    </template>
    <template v-else>
      {{ text }}
    </template>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: [String, Object],
      required: true,
    },
    size: {
      type: String,
      default: 'normal',
    },
    color: {
      type: String,
      default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button' | 'submit' | 'reset'
    },
    handler: {
      type: Function,
      default: null,
    },
  },
  computed: {
    namedColors() {
      return [
        'primary',
        'primary-outline',
        'secondary',
        'tertiary',
        'intelligence',
        'intelligence-outline',
        'destructive',
        'destructive-outline',
        'warning',
        'warning-outline',
        'transparency',
        'unselected-category',
      ];
    },
    isNamedColor() {
      return this.namedColors.includes(this.color) ? this.color : null;
    },
    buttonStyle() {
      return this.namedColors.includes(this.color) ? {} : { background: this.color };
    },
    buttonClass() {
      // Adicione aqui as classes CSS que você precisa
      return 'button ';
    },
  },
  methods: {
    handleClick(event) {
      if (!this.handler) return;
      event.preventDefault();
      this.handler();
    },
  },
};
</script>

<style scoped>
/* Adicione aqui o seu CSS, correspondente ao styles.module.css */

.button {
  font-family: 'Helvetica Now Display Extra Bold', sans-serif;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0); /* resolve o bug do botão mudando de tamanho quando ativado/desativado */
  color: #fff;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  transition: 0.3s;

  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  line-height: 130%;
  height: 3rem;
}

/* === Tamanho do botão =====
	(Nesse caso só muda o padding) */

.button[data-size='thin'] {
  padding: 0.2em;
  height: 2rem;
}

.button[data-size='normal'] {
  padding: 0.75rem 1.5rem;
  height: 2.5rem;
}

.button[data-size='big'] {
  padding: 16px 24px;
  height: 3rem;
}

.button[data-size='bigger'] {
  padding: 1.25rem 1.5rem;
  height: 3.5rem;
  border-radius: 32px;
}
/* fixme: Nome e função real não finalizada. */
.button[data-size='fixed-width'] {
  padding: 12px 16px;
  height: 40px;
  min-width: max-content;
  font-family: 'Helvetica Now Display Bold', sans-serif;
  white-space: nowrap;
}
.button[data-size='fit-content'] {
  padding: 1em 1.5em;
  width: fit-content;
}

/* === Cores do botão ======= */
/* Primário */
.button[data-color='primary'] {
  background: var(--btnColorPrimary);
}

.button[data-color='primary']:hover {
  background: var(--btnColorPrimaryHover);
}

.button[data-color='primary']:active {
  background: var(--btnColorPrimaryActive);
}

/* Primário com outline */

.button[data-color='primary-outline'] {
  background: unset;
  color: var(--btnColorPrimaryActive);
  border: 1px solid var(--btnColorPrimaryActive);
}

.button[data-color='primary-outline']:hover {
  background: unset;
  color: var(--color-background-primary-200);
}

.button[data-color='primary-outline']:active {
  background: unset;
}

/* Secundário */

.button[data-color='secondary'] {
  background: var(--btnColorSecondary);
}

.button[data-color='secondary']:hover {
  background: var(--btnColorSecondaryHover);
}

.button[data-color='secondary']:active {
  background: var(--btnColorSecondaryActive);
}

/* Terciário */

.button[data-color='tertiary'] {
  background: var(--btnColorTertiary);
  color: var(--btnColorTertiaryText);
}

.button[data-color='tertiary']:hover {
  background: var(--btnColorTertiaryHover);
}

.button[data-color='tertiary']:active {
  background: var(--btnColorTertiaryActive);
}

/* intelligence */

.button[data-color='intelligence'] {
  background: var(--btnColorIntelligence);
  color: var(--white);
}

.button[data-color='intelligence']:hover {
  background: var(--btnColorIntelligenceHover);
}

.button[data-color='intelligence']:active {
  background: var(--btnColorIntelligenceHover);
}

/* intelligence com outline */

.button[data-color='intelligence-outline'] {
  background: unset;
  color: var(--btnColorIntelligence);
  border: 1px solid rgb(155, 119, 255);
}

.button[data-color='intelligence-outline']:hover {
  background: unset;
}

.button[data-color='intelligence-outline']:active {
  background: unset;
}

/* Ação destrutiva */

.button[data-color='destructive'] {
  background: var(--btnColorDestructive);
}

.button[data-color='transparency'] {
  background: unset;
  border-color: white;
}

.button[data-color='destructive']:hover {
  background: var(--btnColorDestructiveHover);
}

.button[data-color='destructive']:active {
  background: var(--btnColorDestructiveActive);
}

/* Destrutivo com outline */

.button[data-color='destructive-outline'] {
  background: #ffffff;
  color: rgba(244, 53, 75, 1);
  border: 1px solid rgba(244, 53, 75, 1);
}
.button[data-color='destructive-outline']:hover {
  background: var(--btnColorDestructive);
  color: #ffffff;
}

.button[data-color='destructive-outline']:active {
  background: var(--btnColorDestructive);
  color: #ffffff;
}

/* Cores para usos mais específicos */

.button[data-color='unselected-category'] {
  background: #fff;
  border: 1px solid #eeeeee;
  color: #242b30;
}

.button[data-color='unselected-category']:hover {
  background: #eeeeee;
}

/* Estados do Botão */

.button:disabled:not([data-loading='true']) {
  background: var(--btnColorDisabled);
  color: var(--btnColorDisabledText);
  cursor: not-allowed;
}

.button[data-loading='true'] {
  cursor: progress;
}





/* warning */

.button[data-color='warning'] {
  background: var(--btnColorWarning);
  color: var(--white);
}

.button[data-color='warning']:hover {
  background: var(--btnColorWarningActive);
}

.button[data-color='warning']:active {
  background: var(--btnColorWarningActive);
}

/* warning com outline */

.button[data-color='warning-outline'] {
  background: unset;
  color: var(--btnColorWarningActive);
  border: 1px solid var(--btnColorWarning);
}

.button[data-color='warning-outline']:hover {
  color: var(--btnColorWarning);
  border: 1px solid var(--btnColorWarningActive);
}

.button[data-color='warning-outline']:active {
  background: unset;
}

</style>
