// mediaTypes.js
export const imageTypes = [
    'png',
    'jpg',
    'jpeg',
    'webp',
    'gif',
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp',
    'image/gif',
];

export const videoTypes = [
    'mp4',
    'mov',
    'ogv',
    'webm',
    'video/mp4',
    'video/quicktime',
    'video/ogv',
    'video/webm',
];

export const audioTypes = [
    'mp3',
    'm4a',
    'ogg',
    'opus',
    'audio/mp3',
    'audio/m4a',
    'audio/ogg',
    'audio/opus',
];
