<template>
  <div>
    <apexchart type="line" :options="chartOptions" :series="chartSeries" />
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ['chartData'],
  data() {
    return {
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          categories: this.chartData.labels,
        },
        yaxis: {
          min: 0,
          max: 100,
        },
      },
      chartSeries: [
        {
          name: 'Vendas',
          data: this.chartData.data,
        },
      ],
    };
  },
};
</script>
