<template>
  <div id="app">
    <line-chart :chartData="chartData" />
  </div>
</template>

<script>
import LineChart from '@/components/LineChart.vue';

export default {
  components: {
    LineChart,
  },
  props: {
    views: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    chartData() {
      return this.transformViewsToChartData();
    },
  },
  methods: {
    transformViewsToChartData() {
      const labels = [];
      const data = [];

      this.views.forEach(view => {
        labels.push(`${view.mes}/${view.ano}`);
        data.push(view.total);
      });

      return {
        labels: labels,
        data: data,
      };
    },
  },
};
</script>
