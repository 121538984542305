<template>
  <div>
    <video-player :options="videoOptions" />
  </div>
</template>

<script>
import VideoPlayer from '@/components/DefaultVideoPlayerVue.vue';

export default {
  name: 'VideoExample',
  components: {
    VideoPlayer
  },
  props:{
    className: String,
    url: String,
  },
  data() {
    return {
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src:
                this.url
          }
        ]
      }
    };
  }
};
</script>